import React, { useCallback } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ImgFluid = ({
  image,
  onLoad = null,
  onLoadParams = null,
  ...rest
}) => {
  const imgSrc = getImage(image.src)

  const handleLoaded = useCallback(() => {
    if (onLoad) onLoad(onLoadParams)
  }, [onLoad, onLoadParams])

  const handleStartLoad = useCallback(
    props => {
      if (!props.wasCached) return

      if (onLoad) onLoad(onLoadParams)
    },
    [onLoad, onLoadParams]
  )

  return (
    <GatsbyImage
      alt={image.alt}
      image={imgSrc}
      onLoad={handleLoaded}
      onStartLoad={handleStartLoad}
      {...rest}
    />
  )
}
