import React, { useMemo } from "react"
import { useMedia } from "use-media"
import { VideoLoop } from "./video-loop"
import { ImgFluid } from "./img-fluid"

type TVideoSrcResponsive = {
  src_small?: string
  src_medium?: string
  src_large?: string
}

type TVideoLoopResponsiveProps = {
  video: {
    src: TVideoSrcResponsive | string
  }
  size?: "half" | "third" | "full" | "bleed"
  image?: {
    alt: string
    src: any
  }
  ratio?: number | "$1/1" | "$16/9" | "$9/16" | "$4/3" | "$3/4" | "$5/4" | "$4/5"
}

export const VideoLoopResponsive = (props: TVideoLoopResponsiveProps) => {
  const { ratio, image, size, video } = props

  const showVideo = !useMedia(
    "(prefers-reduced-motion: reduce), (prefers-reduced-data: reduce)"
  )

  const isSmall = useMedia("(max-width: 640px)")
  const isMedium = useMedia("(max-width: 1023px)")
  const isLarge = useMedia("(min-width: 1024px)")

  const videoSrc = useMemo((): any => {
    if (typeof video.src === "string" || video.src instanceof String) {
      return video.src
    }
    // check for small
    if (video.src.src_small && isSmall) {
      return video.src.src_small
    }

    // check for medium
    if (video.src.src_medium && isMedium) {
      return video.src.src_medium
    }

    // check for large
    if (video.src.src_large && isLarge) {
      return video.src.src_large
    }

    return ""
  }, [video, isSmall, isMedium, isLarge])

  return showVideo || !image ? (
    <VideoLoop src={videoSrc} key={videoSrc} ratio={ratio} />
  ) : (
    <ImgFluid image={image} />
  )
}
