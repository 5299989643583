import React, {
  useRef,
  MutableRefObject,
  useState,
  useCallback,
  useEffect,
} from "react"
import { useInView } from "react-intersection-observer"
import { Accelerate, Ratio } from "./primitives"

export type TVideoLoopProps = {
  src: string
  ratio?: number | "$1/1" | "$16/9" | "$9/16" | "$4/3" | "$3/4" | "$5/4" | "$4/5"
}

export const VideoLoop = (props: TVideoLoopProps) => {
  const { ratio = "$16/9", src } = props

  const videoRef: MutableRefObject<HTMLVideoElement | null> = useRef(null)

  const [inViewRef, inView] = useInView({
    threshold: 0.25,
  })

  const playVideo = useCallback(() => {
    videoRef.current.play()
  }, [videoRef])

  const pauseVideo = useCallback(() => {
    videoRef.current.pause()
  }, [videoRef])

  useEffect(() => {
    if (inView) {
      playVideo()
    } else {
      pauseVideo()
    }
  }, [inView, src])

  const setRefs = useCallback(
    node => {
      videoRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  return (
    <Ratio ratio={ratio}>
      <video
        ref={setRefs}
        width="100%"
        height="100%"
        autoPlay={false}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
        aria-hidden={true}
      >
        <source src={src} type="video/mp4" />
      </video>
    </Ratio>
  )
}
